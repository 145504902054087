import { STORE_EARNING_LIST_REQUEST, STORE_EARNING_LIST_SUCCESS, TRANSACTION_LIST_REQUEST, TRANSACTION_LIST_SUCCESS,TRANSACTION_LIST_FAIL, STORE_EARNING_LIST_FAIL, EARNING_CHART_REQUEST, EARNING_CHART_FAIL, EARNING_CHART_SUCCESS, TOTAL_EARNING_REQUEST, TOTAL_EARNING_FAIL, TOTAL_EARNING_SUCCESS } from "../../constants/transactionsConstant"
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import { localData } from "../../helpers/auth/auth";
import { getUrl } from "../../helpers/getUrl";
const token = localData && localData.token


export const transactionApi = createApi({
    reducerPath: "transactionApi",
    baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION, process.env.REACT_APP_ORDER_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
    
        // If we have a token set in state, let's assume that we should be passing it.
        if(token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }}),
      tagTypes: ["Transaction"],
    endpoints: (builder) => ({
        listTransactions: builder.query({
            query: ({offset, limit}) => ({
                url: `/transaction/list?offset=${offset}&limit=${limit}`,
                method: "get",
            }),
           providesTags: ["Transaction"]
        }),
    })
        
});


export const earningApi = createApi({
    reducerPath: "earningApi",
    baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
    
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }}),
      tagTypes: ["Earning"],
    endpoints: (builder) => ({
        earningList: builder.query({
            query: ({page, limit}) => ({
                url: `/wallet/earnings/list?page=${page}&limit=${limit}`,
                method: "get",
            }),
           providesTags: ["Earning"]
        }),
        storeEarningList: builder.query({
            query: ({store_id,page,limit}) => ({
                url: `/wallet/earnings?store_id=${store_id}&page=${page}&limit=${limit}`,
                method: "get",
            }),
           providesTags: ["Earning"]
        }),
        earningChart : builder.query({
            query: ({date}) => ({
                url: `/wallet/earnings/chart?date=${date}`,
                method: "get",
            }),
           providesTags: ["Earning"]
        }),
        totalEarning : builder.query({
            query: ({startDate,endDate}) => ({
                url: `/wallet/earnings/total?startDate=${startDate}&endDate=${endDate}`,
                method: "get",
            }),
           providesTags: ["Earning"]
        }),
    })
        
})


export function StoreEarningList({store_id,page,limit}){
    return async(dispatch,getState) => {
        dispatch({type: STORE_EARNING_LIST_REQUEST});
        try {
     
            const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/wallet/earnings?store_id=${store_id}&page=${page}&limit=${limit}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: STORE_EARNING_LIST_FAIL, payload: data.message})
            }
            dispatch({type: STORE_EARNING_LIST_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: STORE_EARNING_LIST_FAIL, payload: message})
        }
    }
}




export function EarningChart({date}){
    return async(dispatch,getState) => {
        dispatch({type: EARNING_CHART_REQUEST});
        try {
     
            const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/wallet/earnings/chart?date=${date}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: EARNING_CHART_FAIL, payload: data.message})
            }
            dispatch({type: EARNING_CHART_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: EARNING_CHART_FAIL, payload: message})
        }
    }
}




export function TotalEarning({startDate,endDate}){
    return async(dispatch,getState) => {
        dispatch({type: TOTAL_EARNING_REQUEST});
        try {
     
            const res = await fetch(`${getUrl("https://product.thealphashops.com/api/v1", "https://productservice.click/api/v1")}/wallet/earnings/total?startDate=${startDate}&endDate=${endDate}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  ACCEPT: "application/json",
                  "Authorization": `Bearer ${token}`
                }
            });
            const data = await res.json();
            if(data.error){
                dispatch({type: TOTAL_EARNING_FAIL, payload: data.message})
            }
            dispatch({type: TOTAL_EARNING_SUCCESS, payload: data.results})
        } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data
              : error.message;
            dispatch({type: TOTAL_EARNING_FAIL, payload: message})
        }
    }
}


export const {useListTransactionsQuery} = transactionApi;
export const {useTotalEarningQuery, useEarningChartQuery, useEarningListQuery, useStoreEarningListQuery} = earningApi