import { createContext, useState, useRef, useCallback, useReducer} from "react";
import { getLocalData } from './component/AuthPage/Protected/auth';
import { actionTypes } from "./constants/uploadConstants";


export const AppContext = createContext(null) 
const AppContextProvider = ({children}) => {
    const [storeOwnerDetails, setStoreOwnerDetails] = useState("");
    const [buyerDetails, setBuyerDetails] = useState("");
    const [transactionInfo, setTransactionInfo] = useState({})
    const inputElem = useRef("")
    const first_name = getLocalData ? getLocalData() && getLocalData().user && getLocalData().user.first_name : "Admin";
    const [itemLimit, setItemLimit] = useState(10);
    const [selected, setSelected] = useState("");
    const [selectedTransaction, setSelectedTransaction] = useState("");
    const [recentTransaction, setRecentTransaction] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("")
    const [storeOwner, setStoreOwner] = useState("")
    const [toggleDropDown, setToggleDropDown] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [menuSwitch, setMenuSwitch] = useState(1)
    const [showModal, setShowModal] = useState(false);
    const [showGenderModal, setShowGenderModal] = useState(false)
    const [showSizeModal, setShowSizeModal] = useState(false)
    const [showTypeModal, setShowTypeModal] = useState(false);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [showDownloadModal, setShowDownloadModal] =useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
    const [showDeletePaymentModal, setShowDeletePaymentModal] = useState(false);
    const [etaDeleteModal, setEtaDeleteModal] = useState(false)
    const [orderDetails, setOrderDetails] = useState(null);
    const [itemIndex, setItemIndex] = useState(null);
    const [orderedItems, setOrderedItems] = useState(null)
    const [id, setId] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [clientName, setClientName] = useState("");
    const [editProductId, setEditProoductId] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [activeTab, setActiveTab] = useState(1);
    const [activeCustomerTab, setActiveCustomerTab] = useState(1);
    const [refundTab, setRefundTab] = useState(0);
    const [getBasicBuyer, setGetBasicBuyer] = useState(null);  
    const [activeTabOrder, setActiveTabOrder] = useState(1);
  
    const [monthsRange, setMonthsRange] = useState("all");
    const [storeId, setStoreId] = useState("")

    const [dateRange, setDateRange] = useState(false);
    const [weekly, setWeekly] = useState(false);
    const [monthly, setMonthly] = useState(false);
    const [yearly, setYearly] = useState(false);
    const [value, onChange] = useState(new Date());
    const [pageNumber, setPageNumber] = useState(1);



    const INITIAL_STATE = {
      front_view: "",
      rear_view: "",
      left_view: "",
      right_view: "",
      video_url: "",
    };


    const productUploadReducer = (state, action) => {
      switch (action.type) {
        case actionTypes.SET_FRONTVIEW_IMAGE:
              return {...state, front_view: action.payload};
        case actionTypes.SET_LEFTVIEW_IMAGE:
              return {...state, left_view: action.payload};
        case actionTypes.SET_RIGHTVIEW_IMAGE: 
              return {...state, right_view: action.payload};
        case actionTypes.SET_REARVIEW_IMAGE:
              return {...state, rear_view: action.payload};
        case actionTypes.SET_VIDEO:
              return {...state, video_url: action.payload};
        default:
            return state;
      }
    };

    const [state, uploadDispatch] = useReducer(productUploadReducer, INITIAL_STATE)

    const handleDateRange = () => {
      setDateRange(prev => !prev);
      setWeekly(false);
      setMonthly(false);
      setYearly(false);
    }
    const handleCalenderWeekly = () => {
        setWeekly(prev => !prev);
        setMonthly(false);
        setYearly(false);
        setDateRange(false);
    }
    const handleCalenderMonthly = () => {
        setMonthly(prev => !prev)
        setWeekly(false);
        setYearly(false);
        setDateRange(false);
    }
    const handleCalenderYearly = () => {
      setYearly(prev => !prev);
      setWeekly(false);
      setMonthly(false);
      setDateRange(false)
  }

  const clearCalenderData = () => {
    setYearly(false);
    setWeekly(false);
    setMonthly(false);
    setDateRange(false);
  }

    const handleActiveTabOrder = (tab) => {
      setActiveTabOrder(tab)
    }
    const getMonthData = (month) => {
        if(month === "0-3"){
            return ["January", "February", "March", "April"]
        }else if(month === "4-7"){
            return ["May", "June", "July", "August"]
        }else if(month === "8-11"){
            return ["September", "October", "November", "December"]
        }else {
            return  ["January", "February", "March", "April","May", "June", "July", "August","September", "October", "November", "December"]
        }
    }
  
  const openModal = () => {
      setShowModal(prev => !prev)
  }
  
  const openGenderModal = () => {
    setShowGenderModal(prev => !prev)
  }
  const openSizeModal = () => {
    setShowSizeModal(prev => !prev)
  }
  const openTypeModal = () => {
    setShowTypeModal(prev => !prev)
  }
  
  const openDownloadModal = () => {
    setShowDownloadModal(prev => !prev)
  } 
    const handleMenuSwitch = (menu, id) => {
      setMenuSwitch(menu);
      setId(id)
    }
  
    const handlePaymentModal = () => {
        setShowPaymentModal(prev => !prev)
    }
  
    const handleEditPaymentModal = () => {
         setShowEditPaymentModal(prev => !prev)
    }
    const handleDeletePaymentModal = () => {
      setShowDeletePaymentModal(prev => !prev)
   }
   const handleActiveTab = (tab) => {
    setActiveTab(tab)
  }
  const handleRefundTab = (index) => {
     setRefundTab(index)
  }
 
  // const handleCustomerActiveTab = (tab) => {
  //   setActiveCustomerTab(tab)
  // }
 
   
    const numberSeperator = (num) => {
        return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    function nFormatter(num) {
      if (num >= 1000000000) {
         return (num / 1000000000).toFixed(3).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
         return (num / 1000000).toFixed(3).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
         return (num / 1000).toFixed(3).replace(/\.0$/, '') + 'K';
      }
      return num;
 }
    const toggleAccordion = (index) => {
        if(selected === index){
            return setSelected(null)
        }
        setSelected(index)
    }

    const toggleStatus = (index) => {
      if(selectedStatus === index){
          return setSelectedStatus(null)
      }
      setSelectedStatus(index)
  }

    const selectedIndexCheck = (index) => {
          return setSelectedIndex(index)
    }
    const handleNotificationPanel = () => {
        if(notificationOpen !== true){
          setNotificationOpen(true)
        }else {
          setNotificationOpen(false)
        }
    
      }
      const handleToggle = () => {
        setToggleDropDown(!toggleDropDown)
        
      }
      const productQty = (qty) => {
        return qty.map(data => data).length
       }  
   
      const loadMoreSlice = (data, noOfElement) => {
          return data.slice(0,noOfElement)
      }
      const loadMorePagination = (changeData, noOfElement, data) => {
           const checkDataLength = noOfElement >= data.length ?  data.length : (noOfElement + noOfElement);
           changeData(checkDataLength)
      }
      const toggleTransactions = (index) => {
        if(selectedTransaction === index){
            return setSelectedTransaction(null)
        }
        setSelectedTransaction(index)
    }
    const toggleRecentTransactions = (index) => {
      if(recentTransaction === index){
          return setRecentTransaction(null)
      }
      setRecentTransaction(index)
  }

  const handleNextPage = useCallback(
    (direction, totalPages, setItemPage, page) => {
      if(direction === "next" && page < totalPages){
        setItemPage((prev) => prev + 1);
      }else if(direction === "prev" && page > 1){
        setItemPage((prev) => prev - 1);
      }
    },
    []
  );
  

 
    const {Provider} = AppContext;
    return (
        <Provider value={{first_name, numberSeperator, itemLimit, setItemLimit, selected, setSelected, toggleAccordion, storeOwner, setStoreOwner, toggleDropDown, setToggleDropDown, notificationOpen, setNotificationOpen, handleNotificationPanel, handleToggle, menuSwitch, setMenuSwitch, showModal, setShowModal, showGenderModal, setShowGenderModal, showSizeModal, setShowSizeModal, showTypeModal, setShowTypeModal, showTransactionModal, setShowTransactionModal, showDownloadModal, setShowDownloadModal, showPaymentModal, setShowPaymentModal, showEditPaymentModal, setShowEditPaymentModal, showDeletePaymentModal, setShowDeletePaymentModal, id, setId, openDownloadModal, handleMenuSwitch, openModal, openGenderModal, openSizeModal, handlePaymentModal, handleEditPaymentModal, handleDeletePaymentModal, openTypeModal, productQty, orderDetails, setOrderDetails, itemIndex, setItemIndex, orderedItems, setOrderedItems, selectedIndexCheck, selectedIndex, setSelectedIndex, searchTerm, setSearchTerm, inputElem, toggleStatus, selectedStatus, clientName, setClientName, editProductId, setEditProoductId, openDeleteModal, setOpenDeleteModal, storeOwnerDetails, setStoreOwnerDetails, buyerDetails, setBuyerDetails, activeTab, setActiveTab, handleActiveTab, refundTab, setRefundTab, handleRefundTab, getBasicBuyer, setGetBasicBuyer,setEtaDeleteModal, etaDeleteModal,monthsRange, setMonthsRange,getMonthData,nFormatter,loadMoreSlice,loadMorePagination,selectedTransaction, setSelectedTransaction,toggleTransactions,  activeTabOrder, setActiveTabOrder,handleActiveTabOrder, activeCustomerTab, setActiveCustomerTab, weekly, setWeekly, monthly, setMonthly, yearly, setYearly, value, onChange, handleCalenderWeekly, handleCalenderMonthly, handleCalenderYearly, recentTransaction, setRecentTransaction,toggleRecentTransactions, clearCalenderData,storeId, setStoreId, transactionInfo, setTransactionInfo,pageNumber, setPageNumber, handleNextPage, dateRange, setDateRange, handleDateRange, state, uploadDispatch}}>
            {children}
        </Provider>
    )
}


export default AppContextProvider