import {useEffect, useContext} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import { useSelector, useDispatch  } from 'react-redux';

import { listAdmins } from './actions/Admin/AdminAction';
import { AppContext } from "./AppContext";
import LandingPage from './LandingPage/LandingPage';
import ResetPassword from "./component/AuthPage/ResetPassword";
import NewPassword from "./component/AuthPage/NewPassword";
import Home from './Home/Home';

import Login from './component/AuthPage/Login';
import Auth from "./helpers/Auth";
import ProtectedRoute from "./component/AuthPage/Protected/IsAuthenticated";

function App() {
  const {notificationOpen, setNotificationOpen,  showModal, setShowModal, showGenderModal, setShowGenderModal, showTypeModal, setShowTypeModal, showSizeModal, setShowSizeModal, showTransactionModal, setShowTransactionModal,showDownloadModal, setShowDownloadModal, showPaymentModal, setShowPaymentModal, showEditPaymentModal, setShowEditPaymentModal, showDeletePaymentModal, setShowDeletePaymentModal, itemLimit} = useContext(AppContext)
  const dispatch = useDispatch()
  const {error} = useSelector(state => state.fetchAdmin);

  useEffect(() => {
    const page = 1;
    const limit = +itemLimit;
    const data = {page, limit}
   dispatch(listAdmins(data));
   
}, [dispatch, itemLimit]);
useEffect(() => {
  if(error &&  error.includes("Invalid token")){
    Auth.deauthenticateUser()
  }
}, [error])
 
  return (
    <>

        <div className="menu_items">
      
          {showModal ?  <div className="bg__overlay" onClick={() => setShowModal(false)}></div>: null}
          {showGenderModal ? <div className="bg__overlay_gender" onClick={() => setShowGenderModal(false)}></div>: null}
          {showTypeModal ? <div className="bg__overlay_type" onClick={() => setShowTypeModal(false)}></div>: null}
          {showSizeModal ? <div className="bg__overlay_size" onClick={() => setShowSizeModal(false)}></div>: null}
          {notificationOpen ? <div className="bg_notification__overlay" onClick={() => setNotificationOpen(false)}></div>: null}
          {showTransactionModal ? <div className="bg_overlay_transaction" onClick={() => setShowTransactionModal(false)}></div>: null}
          {showDownloadModal ? <div className="bg_overlay_transaction" onClick={() => setShowDownloadModal(false)}></div>: null}
          {showPaymentModal ? <div className="bg_overlay_transaction" onClick={() => setShowPaymentModal(false)}></div>: null}
          {showEditPaymentModal ? <div className="bg_overlay_transaction" onClick={() => setShowEditPaymentModal(false)}></div>: null}
          {showDeletePaymentModal ? <div className="bg_overlay_transaction" onClick={() => setShowDeletePaymentModal(false)}></div>: null}
 
          <Router>
              <Route exact path="/" render={(props) => <LandingPage {...props} />} />
              <ProtectedRoute  path="/dashboard" component={Home} isAuth={Auth.isUserAuthenticated()}/>
              <Route path="/login" render={(props) => <Login {...props}/>} />
              <Route exact path="/forgot-password" render={(props) => <ResetPassword {...props}/>} />
              <Route exact path="/reset" render={(props) => <NewPassword {...props}/>} />   
          </Router>
        </div>
    </>
  );
}

export default App;
