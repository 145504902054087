import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { localData } from "../../../helpers/auth/auth";
import { getUrl } from "../../../helpers/getUrl";

const token = localData && localData.token


export const allProductsApi = createApi({
    reducerPath: "allProductsApi",
    baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
    
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }}),
      tagTypes: ["AllProducts"],
      endpoints: (builder) => ({
        allProducts: builder.query({
            query: ({page, limit}) => ({
                url: `/product/all?page=${page}&limit=${limit}`,
                method: "get",
            }),
           providesTags: ["AllProducts"]
        }),
        searchProducts: builder.query({
            query: ({searchTerm}) => ({
                url: `/product/all?searchTerm=${searchTerm === undefined ? "" : searchTerm}`,
                method: "get",
            }),
           providesTags: ["AllProducts"]
        }),
        updateProductFiles: builder.mutation({
            query: (body) => ({
                url: `product/update`,
                method: "put",
                body
            }),
           invalidatesTags: ["AllProducts"]
        })
    })
});


export const {useAllProductsQuery, useUpdateProductFilesMutation, useLazySearchProductsQuery} = allProductsApi