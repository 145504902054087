import {useContext, useEffect} from "react";
import {Route,Switch, useLocation, useHistory, withRouter} from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';

import { Layout} from 'antd';
import { AppContext } from "../AppContext";
import AdminRole from "../component/AdminRole/AdminRole";
import CustomersDB from "../component/CustomersDB/CustomersDB";
import Dashboard from "../component/Dashboard";
import DropDown from "../component/Dropdown";
import NotificationPanel from "../component/NotificationPanel";
import Tabs from "../component/Orders/Tabs";
import PaymentMethods from "../component/PaymentMethods/PaymentMethods";
import ListProducts from "../component/Product/ProductPage";
import Sale from "../component/Sales/Sale";
import TransactionPage from "../component/Transactions/TransactionPage";
import Headers from "../Header/Header";
import SideBar from "../SideBar";

import AddProduct from "../component/Product/AddProductCategory";
import EditProduct from './../component/Product/EditProduct';
import AllSubCategories from "../component/Product/AllSubCategories";
import Auth from "../helpers/Auth";
import WaitList from "../component/WaitList/WaitList";
import StoreOwnerItem from './../component/CustomersDB/StoreOwnerItem';
import BuyerItem from "../component/CustomersDB/BuyerItem";
import Refund from "../component/Refund/Refund";
import Settings from "../component/Settings/Settings";
import Reasons from "../component/Settings/Reasons";
import Permissions from "../component/Settings/Permissions";
import VehicleTypes from "../component/Settings/VehicleTypes";
import Eta from "../component/Settings/Eta";
import AddEta from "../component/Settings/AddEta";
import ViewTransactions from "../component/Transactions/ViewTransactions";
import ProductValidation from "../component/ProductValidation.js/ProductValidation";
import ValidationDetails from "../component/ProductValidation.js/ValidationDetails";
import Checklists from './../component/Settings/ProductChecklist/Checklists';

import "./../sidebar.css";
import "./../merchants.css"
import { clearTrackerState } from "../actions/Admin/Tracker";
import Coupon from "../component/Coupon/Coupon";
import AllMerchantsProducts from "../component/MerchantsProducts/AllMerchantProducts";
import ImagesVideosUpload from "../component/MerchantsProducts/ImagesVideosUpload";

const { Content } = Layout;

const Home = (props) => {
  const { match } = props;
  const dispatch = useDispatch()
  const {setEtaDeleteModal, etaDeleteModal,setActiveTabOrder} = useContext(AppContext)
  const history = useHistory() 
  const search = useLocation().search;
  const display = new URLSearchParams(search).get("detail");
  const type = new URLSearchParams(search).get("type");
  const reason = new URLSearchParams(search).get("reason");
  const permission = new URLSearchParams(search).get("permission");
  const vehicle = new URLSearchParams(search).get("vehicle");
  const categoryDelete = new URLSearchParams(search).get("id");
  const checkCategoryDel = categoryDelete && categoryDelete.slice(25);
  const checkSubCatDel = categoryDelete && categoryDelete.slice(25);
  const createPayment = new URLSearchParams(search).get("create");
  const editPayment = new URLSearchParams(search).get("edit");
  const deletePayment = new URLSearchParams(search).get("delete");
  const {openDeleteModal, setOpenDeleteModal} = useContext(AppContext);

  const roleModal = new URLSearchParams(search).get("role");

  const {setSelected} = useContext(AppContext);

  const {error} = useSelector(state => state.fetchAdmin);
  useEffect(() => {
    if(error &&  error.includes("Invalid token")){
      Auth.deauthenticateUser()
    }
}, [error])


  return (
    <>
       
        <Layout style={{ minHeight: '100vh' }}>
          <SideBar match={match}/>
          <Layout className="site-layout">
            <Headers />
            <NotificationPanel />
            <DropDown />
            <Content style={{ margin: '24px 16px 0' }} key="1" className="content_page">
            
              <div className="site-layout-background dashboard-content">
                  <Switch>
                    <Route exact path={`${match.url}`} render={(props) => <Dashboard {...props} />} />
                    <Route exact path={`${match.url}/orders`} render={(props) => <Tabs {...props} />} />
                    <Route exact path={`${match.url}/products`} render={(props) => <AllMerchantsProducts {...props} />} />
                    <Route exact path={`${match.url}/products/:name/:productId/:userId`} render={(props) => <ImagesVideosUpload {...props} />} />
                    <Route exact path={`${match.url}/customer-database`} render={(props) => <CustomersDB {...props} />} />
                    <Route exact path={`${match.url}/sales`} render={(props) => <Sale {...props} />} />
                    <Route exact path={`${match.url}/transactions`} render={(props) => <TransactionPage {...props} />} />
                    <Route exact path={`${match.url}/settings`} render={(props) => <Settings {...props}/>} />
                    <Route exact path={`${match.url}/settings/reasons`} render={(props) => <Reasons {...props} />} />
                    <Route exact path={`${match.url}/settings/permissions`} render={() => <Permissions {...props}/>} />
                    <Route exact path={`${match.url}/settings/vehicle-types`} render={() => <VehicleTypes {...props}/>} />
                    <Route exact path={`${match.url}/settings/eta`} render={() => <Eta {...props} />}/>
                    <Route exact path={`${match.url}/settings/eta/add-eta`} render={() => <AddEta {...props} />}/>
                    <Route path={`${match.url}/settings/product/checklists`} render={(props) => <Checklists {...props} />} />
                    <Route exact path={`${match.url}/categories`} render={(props) => <ListProducts {...props} />} />
                    <Route exact path={`${match.url}/categories/add`} render={(props) => <AddProduct {...props} />} />
                    <Route exact path={`${match.url}/categories/edit`} render={(props) => <EditProduct {...props} />} />
                    <Route exact path={`${match.url}/categories/sub-categories`} render={(props) => <AllSubCategories {...props} />} />
                    <Route exact path={`${match.url}/payment-methods`} render={(props) => <PaymentMethods {...props} />} />
                    <Route exact path={`${match.url}/roles`} render={(props) => <AdminRole {...props} />} />
                    <Route exact path={`${match.url}/refund`} render={(props) => <Refund {...props}/>} />
                    <Route exact path={`${match.url}/waitlist`} render={(props) => <WaitList {...props} />} />
                    <Route exact path={`${match.url}/coupon`} render={(props) => <Coupon {...props} />} />
                    <Route exact path={`${match.url}/customer-database/storeowner-details/:id`} render={(props) => <StoreOwnerItem {...props} />} />
                    <Route exact path={`${match.url}/customer-database/basic-buyers-details/:buyerId`} render={(props) => <BuyerItem {...props} />} />
                    <Route exact path={`${match.url}/transactions/history`} render={(props) => <ViewTransactions {...props} />} />
                    <Route path={`${match.url}/product/validation`} render={(props) => <ProductValidation {...props} />} />
                    <Route path={`${match.url}/product/new/details/:id`} render={(props) => <ValidationDetails {...props} urlPath="new"/>} />
                    <Route path={`${match.url}/product/approved/details/:id`} render={(props) => <ValidationDetails {...props} urlPath="approved"/>} />
                    <Route path={`${match.url}/product/rejected/details/:id`} render={(props) => <ValidationDetails {...props} urlPath="rejected"/>} />
                    <Route path={`${match.url}/product/all/details/:id`} render={(props) => <ValidationDetails {...props} urlPath="all"/>} />
                    
                </Switch>
              </div>
            </Content>
          </Layout>
          {(display && display === "new") || (display && display === "all") || (display && display === "processing") || (display && display === "shipped") || (display && display === "delivered") || (display && display === "declined") || (display && display === "unverified") ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/orders"); setSelected(null);setActiveTabOrder(prev => prev); dispatch(clearTrackerState())}}/> : null} 
          {(type && type === "gender") || (type && type === "size") || (type && type === "color") || (type && type === "type") || (type && type === "edit-type") ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/categories/subcategories"); setSelected(null)}}/> : null}
         { checkSubCatDel && checkSubCatDel === "edit-category-type" ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/categories"); setSelected(null)}}/> : null}

          {checkCategoryDel && checkCategoryDel === "category-delete" ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/categories"); setSelected(null)}}/> : null }
          {checkSubCatDel && checkSubCatDel === "sub-category-delete" ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/categories"); setSelected(null)}}/> : null}
          {checkCategoryDel && checkCategoryDel === "delete-sub-category" ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/categories/sub-categories"); setSelected(null)}}/> : null }
          {checkCategoryDel && checkCategoryDel === "edit-sub-category" ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/categories/sub-categories"); setSelected(null)}}/> : null }
          {createPayment === "new" || editPayment === "edit" || deletePayment === "yes" ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/payment-methods"); setSelected(null)}}/> : null }
          {roleModal === "delete-role" ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/roles"); setSelected(null)}}/> : null}
          {openDeleteModal ? <div className="bg__overlay" onClick={() => {setSelected(null); setOpenDeleteModal(false)}}/> : null}
          {reason === "add-reasons"  ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/settings/reasons"); setSelected(null)}}/> : null }
          {permission === "add-permission"  ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/settings/permissions"); setSelected(null)}}/> : null }
          {vehicle === "add-vehicles"  ? <div className="bg__overlay" onClick={() => {history.push("/dashboard/settings/vehicle-types"); setSelected(null)}}/> : null }
          {etaDeleteModal ? <div className="bg__overlay" onClick={() => {setEtaDeleteModal(false); setSelected(null)}}/> : null}
        </Layout>
    </>
  );
}

export default withRouter(Home);