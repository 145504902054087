import { useState, useContext, useEffect } from "react";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { useAllProductsQuery, useLazySearchProductsQuery } from "../../actions/Admin/products/AllProducts"
import { AppContext } from "../../AppContext";
import { handleAlphaError } from "../../utils/handleAlphaError";
import { Link } from "react-router-dom";

import "./allmerchantsproducts.css";
import Loaders from "../Reusables/Loader";
import { actionTypes } from "../../constants/uploadConstants";
import useDebounce from "../../helpers/useDebounce";

const AllMerchantsProducts = () => {
    const [itemPage, setItemPage] = useState(1)
    const {itemLimit, setItemLimit, handleNextPage, searchTerm, uploadDispatch} = useContext(AppContext);
    const {isLoading, isError, isFetching, error, data} = useAllProductsQuery(
        {page: itemPage, limit: itemLimit},
        { enabled: itemPage !== undefined && itemLimit !== undefined }
    );

     const [triggerSearch, { data: dataSearch }] = useLazySearchProductsQuery();
    const errorMessage = handleAlphaError(isError,error, "data");


    const imagePrefill = (front_view, left_view, right_view, rear_view, video_url) => {
        uploadDispatch({ type: actionTypes.SET_FRONTVIEW_IMAGE, payload: front_view });
        uploadDispatch({ type: actionTypes.SET_LEFTVIEW_IMAGE, payload: left_view });
        uploadDispatch({ type: actionTypes.SET_RIGHTVIEW_IMAGE, payload: right_view });
        uploadDispatch({ type: actionTypes.SET_REARVIEW_IMAGE, payload: rear_view });
        uploadDispatch({ type: actionTypes.SET_VIDEO, payload: video_url });
    }

    
    const result = data?.results || {};
    const docs = result?.docs || [];
    const totalPages = result?.totalPages || 0;
    const page = result?.page || 1;


    const searchResult = dataSearch?.results || {};
    const searchDocs = searchResult?.docs || [];


    const merchantProductsList = searchTerm !== "" ? searchDocs : docs;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);


    // Effect to handle search logic based on debounced value
    useEffect(() => {
        if(debouncedSearchTerm === "" || debouncedSearchTerm){
            triggerSearch({searchTerm: debouncedSearchTerm});
        }
    }, [debouncedSearchTerm, triggerSearch]);
    
  return (
    <>
        <div className="mt-4 card card_wrapper  seller_table_wrapper">
            <div className="data_heading">
                <div className="header-title">ALL PRODUCTS  
                    {/* <span>({all_products_length})</span> */}
                </div>
            </div>
            <div className="card-body sub-category-content-body">
                {isError && 
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Error!</h4>
                        <hr/>
                        <p className='text-center text-danger login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>
                    </div>
                    
                }
                   {
                   isLoading && <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={isLoading} color="#000000" size={30} /></div>
                }
                {
                    merchantProductsList && merchantProductsList.length > 0 ? (
                        <div className="table-responsive">
                        <table className="table">
                            <thead>
                                    <tr className="validate-product__sec">
                                        <th scope="col">Alpha Code</th>
                                        <th scope="col">Product name</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Store Name</th>
                                        <th scope="col"></th>
                                    </tr>
                            </thead>
                            
                            <tbody>
                                
                                {
                              merchantProductsList && merchantProductsList?.length > 0 && merchantProductsList.map(({alphaCode,_id,productName,categoryName, store:{name}, createdBy,front_view, left_view, right_view, rear_view, video_url}) => (
                                        <tr className="validate-product__body" key={_id}>
                                        
                                            <td>{alphaCode}</td>
                                            <td>{productName}</td>
                                            <td>{categoryName}</td>
                                            <td>{name}</td>
                                            {/* <td className='approved'>{status}</td> */}
                                            <td className="product__update-link" onClick={() => imagePrefill(front_view, left_view, right_view, rear_view, video_url)}>
                                                <Link to={`/dashboard/products/${name !== "" ? name : "merchant"}/${_id}/${createdBy}`}>
                                                    update pictures
                                                </Link>
                                            </td>
                                        </tr>
                                    ))         
                            
                                }
                            </tbody>
                           
                        </table>
                        {
                           searchTerm === "" && (
                                <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                                        
                                <div className="page_number">Item per page {" "}{" "}
                                    <input type="number" value={itemLimit}   onChange={(e) => setItemLimit(Number(e.target.value))} min={0}/>
                                </div>
                                { totalPages > 1 ? 
                                    <div className="paginate_reuse">
                                        <button disabled={isFetching || isLoading} className="left-chevron" onClick={() => handleNextPage("prev", totalPages, setItemPage, page)}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button disabled={isFetching || isLoading} className="right-chevron" onClick={() => handleNextPage("next", totalPages, setItemPage, page)}><BsChevronRight /></button>
                                    </div>: null
                                }
                            </div>   
                                )
                        }
                    </div>
                    ) : <>{!isLoading && <h2 className="text-center no-record-found">No Records Found</h2>}</>
                }
              
            </div>
        </div>

    </>
  )
}

export default AllMerchantsProducts