import { useContext, useState, useEffect} from "react";
import {MdDashboard, MdPayment,MdSubscriptions} from "react-icons/md";
import {GoVerified} from "react-icons/go"
import {AiFillDatabase, AiOutlineTags} from "react-icons/ai"
import { FiUserPlus} from "react-icons/fi";
import {IoServerSharp} from "react-icons/io5"
import {IoIosBrowsers} from "react-icons/io";
import {FiArchive} from "react-icons/fi";
import { BiWallet } from "react-icons/bi";
import {FaMoneyBill} from "react-icons/fa";
import {CgUserList} from "react-icons/cg";
import { RiCoupon5Line } from "react-icons/ri";
import {  Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { AppContext } from "./AppContext";

const { Sider } = Layout;


const SideBar = () => {
    const {setNotificationOpen} = useContext(AppContext);
    const [url, setUrl] = useState("")
    const dataUrl = window.location.href.split("/").pop()

    const activeClass = (path) => {
        return  path !== window.location.pathname ? "ant-menu-item" : "ant-menu-item active-class"
    }
    const categoryPaths = window.location.pathname === "/dashboard/categories/add" ? "/dashboard/categories/add" : window.location.pathname === "/dashboard/categories/edit" ? "/dashboard/categories/edit" :  window.location.pathname  === "/dashboard/categories" ? "/dashboard/categories" : window.location.pathname === "/dashboard/categories/edit" ? "/dashboard/categories/edit" : window.location.pathname === "/dashboard/categories/sub-categories" ? "/dashboard/categories/sub-categories" : null;
    const customerDBPath = window.location.pathname === "/dashboard/customer-database" ? "/dashboard/customer-database" : window.location.pathname === "/dashboard/customer-database/storeowner-details" ? "/dashboard/customer-database/storeowner-details" : window.location.pathname === "/dashboard/customer-database/basic-buyers-details" ? "/dashboard/customer-database/basic-buyers-details" : null;

    const transactionPaths = window.location.pathname === "/dashboard/transactions" ? "/dashboard/transactions" : window.location.pathname === "/dashboard/transactions/history" ? "/dashboard/transactions/history"  : null;
    const validateTransactionsPaths = window.location.pathname === "/dashboard/product/validation" ? "/dashboard/product/validation" : window.location.pathname === "/dashboard/product/validation/new" ? "/dashboard/product/validation/new" : window.location.pathname === "/dashboard/product/validation/approved" ? "/dashboard/product/validation/approved" : window.location.pathname === "/dashboard/product/validation/rejected" ? "/dashboard/product/validation/rejected" : window.location.pathname === "/dashboard/product/validation/all" ? "/dashboard/product/validation/all" : window.location.pathname === `/dashboard/product/new/details/${url}` ? `/dashboard/product/new/details/${url}` : window.location.pathname === `/dashboard/product/approved/details/${url}` ? `/dashboard/product/approved/details/${url}` : window.location.pathname === `/dashboard/product/rejected/details/${url}` ? `/dashboard/product/rejected/details/${url}` : window.location.pathname === `/dashboard/product/all/details/${url}` ? `/dashboard/product/all/details/${url}` : null;
 
    const allProductsPath = window.location.pathname === "/dashboard/products" ? "/dashboard/products" : window.location.pathname === `/dashboard/products/${url}` ? `/dashboard/products/${url}` : null
const notificationClose = () => {
      setNotificationOpen(false)
}


const menuItems = [
      {
        key: "/dashboard",
        icon: <MdDashboard />,
        label: (
          <Link to="/dashboard" onClick={notificationClose}>
            <span> Dashboard &nbsp;</span>
          </Link>
        ),
        className: activeClass("/dashboard"),
      },
      {
        key: "/dashboard/orders",
        icon: <IoIosBrowsers />,
        label: (
          <Link to="/dashboard/orders" onClick={notificationClose}>
            Order
          </Link>
        ),
        className: activeClass("/dashboard/orders"),
      },
      {
            key: "/dashboard/products",
            icon: <AiOutlineTags />,
            label: (
              <Link to="/dashboard/products" onClick={notificationClose}>
                 All Products
              </Link>
            ),
            className: activeClass(allProductsPath),
      },
      {
        key: "/dashboard/product/validation",
        icon: <GoVerified />,
        label: (
          <Link to="/dashboard/product/validation" onClick={notificationClose}>
            Upload Validation
          </Link>
        ),
        className: activeClass(validateTransactionsPaths),
      },
      {
        key: "/dashboard/customer-database",
        icon: <AiFillDatabase />,
        label: (
          <Link to="/dashboard/customer-database" onClick={notificationClose}>
            Customer Database
          </Link>
        ),
        className: activeClass(customerDBPath),
      },
      {
        key: "/dashboard/sales",
        icon: <IoServerSharp />,
        label: (
          <Link to="/dashboard/sales" onClick={notificationClose}>
            Sales
          </Link>
        ),
        className: activeClass("/dashboard/sales"),
      },
      {
        key: "/dashboard/transactions",
        icon: <MdPayment />,
        label: (
          <Link to="/dashboard/transactions" onClick={notificationClose}>
            Transactions
          </Link>
        ),
        className: activeClass(transactionPaths),
      },
      {
        key: "/dashboard/categories",
        icon: <FiArchive />,
        label: (
          <Link to="/dashboard/categories" onClick={notificationClose}>
            Products
          </Link>
        ),
        className: activeClass(categoryPaths),
      },
      {
        key: "/dashboard/payment-methods",
        icon: <BiWallet />,
        label: (
          <Link to="/dashboard/payment-methods" onClick={notificationClose}>
            Payment Method
          </Link>
        ),
        className: activeClass("/dashboard/payment-methods"),
      },
      {
        key: "/dashboard/roles",
        icon: <FiUserPlus />,
        label: (
          <Link to="/dashboard/roles" onClick={notificationClose}>
            Roles
          </Link>
        ),
        className: activeClass("/dashboard/roles"),
      },
      {
        key: "/dashboard/refund",
        icon: <FaMoneyBill />,
        label: (
          <Link to="/dashboard/refund" onClick={notificationClose}>
            Refund/Complaints
          </Link>
        ),
        className: activeClass("/dashboard/refund"),
      },
      {
        key: "/dashboard/subscription",
        icon: <MdSubscriptions />,
        label: (
          <Link to="/dashboard/subscription" onClick={notificationClose}>
            Subscription
          </Link>
        ),
        className: activeClass("/dashboard/subscription"),
      },
      {
        key: "/dashboard/waitlist",
        icon: <CgUserList />,
        label: (
          <Link to="/dashboard/waitlist" onClick={notificationClose}>
            Wait List
          </Link>
        ),
        className: activeClass("/dashboard/waitlist"),
      },
      {
        key: "/dashboard/coupon",
        icon: <RiCoupon5Line />,
        label: (
          <Link to="/dashboard/coupon" onClick={notificationClose}>
            Coupon
          </Link>
        ),
        className: activeClass("/dashboard/coupon"),
      },
];

 useEffect(() => {
        if(dataUrl){
             setUrl(dataUrl)
        } 
   }, [dataUrl]);
  
   return (
    <Sider
    breakpoint="lg"
    collapsedWidth="0"
    onBreakpoint={broken => {
   
    }}
    onCollapse={(collapsed, type) => {
     
    }}
  >
    <div className="logo"><img src="/alpha-logo.png" alt="Gig logo"/></div>
      <Menu 
       theme="dark"
        mode="inline"
        defaultSelectedKeys={["/dashboard"]} 
        items={menuItems} 
        className="custom-menu"
      />
    </Sider>
   )
    
}


export default SideBar