import { useContext} from 'react';
import { AppContext } from '../../AppContext';
import { useDispatch, useSelector } from 'react-redux';
import {BsArrowLeft} from "react-icons/bs";
import { Link, useLocation, useHistory } from 'react-router-dom';
import Popup from '../Reusables/Popup';
import { TrackOrderAction, clearTrackerState } from './../../actions/Admin/Tracker';
import Tracker from '../Tracker/Tracker';
const OrderItems = ({items, receiverName}) => {
    const history = useHistory()
    const {setItemIndex, orderedItems,  itemIndex, numberSeperator, setSelected} = useContext(AppContext)
    const dispatch = useDispatch(null);
    const search = useLocation().search;
    const display = new URLSearchParams(search).get("detail");
    const preShipmentItems = orderedItems && orderedItems.PreShipmentItems;
    const {tracker_loading, tracker_result, error} = useSelector(state => state.trackerReducer);
    const data = preShipmentItems && preShipmentItems.filter(item => item._id === itemIndex && itemIndex)[0];
    const trackingId = orderedItems && orderedItems.way_bill;
    const trackOrder = trackingId && trackingId;
    console.log("order items", items)
  
    const textStyle = data && data.status === "accepted" ? "text-delivered" : "text-rejected";

    return (

        <div>
             {items ? <>


                        <table className="table  mt-3" align="middle">
                                    <thead className="sub-item-header">
                                        <tr>
                                            <th scope="col">Product Name</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Store Name</th>
                                            <th scope="col">Client Name</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    {items && items.map((item, index) => (
                                    <tbody align="middle" key={item._id}>
                                        <tr>
                                        <td  className="order-item-name">{item && item.ItemName}</td>
                                        <td className="item-ordered-category">{item.category}</td>
                                        <td className="item-ordered-qty">{item.Quantity}</td>
                                        <td className="store-ordered-from">{item.store_name}</td>
                                        <td className="Client-name">{receiverName && receiverName}</td>
                                        <td className="order-details"><Link to={`/dashboard/orders?detail=all`} onClick={() => {setItemIndex(item._id);  dispatch(TrackOrderAction(trackOrder))}}>View Details</Link></td>
                                       
                                        </tr>
                                    
                                    </tbody>
                                    ))}
                            </table>
                { display && display === "all" && preShipmentItems?.length > 0 &&
                    <Popup>   
                        <div className="popup-header">                 
                            <div className="popup-header-container">
                                <div className="popup-back-arrow" onClick={() => {history.push("/dashboard/orders"); setSelected(null); dispatch(clearTrackerState())}}><BsArrowLeft /></div>
                                {
                                    data &&  <div className="order-item-name-d">
                                           {data && data.ItemName.toUpperCase()}  - &#x20A6;{numberSeperator(data && data.Value)}
                                    </div> 
                                }
                                
                               
                               
                            </div> 
                            <div className="popup-body">
                                {tracker_loading ?  <p>Loading...</p> : tracker_result && Object.keys(tracker_result).length > 0  && (trackOrder !== "" || trackOrder !== undefined) ? <>
                                   <Tracker tracked_data={tracker_result} trackOrder={trackOrder} tracked_error={error}/>
                                </> : error && trackOrder === undefined ? <p className="waybill-no-details text-danger">{error || error.message}</p> : null
                      
                               } 
                                   
                                    <div className="row">
                                        <div className="col-4 col-lg-4">
                                            <div className="image-data">
                                                <img src={data && data.product_image} alt={data && data.product_image}/>
                                            </div>
                                            <div className="color-size-sec">
                                                {data && data.color &&                                                 
                                                    <div> 
                                                        <div className="product-color-oms">Color</div>
                                                        <div style={{backgroundColor: data.color, width: "25px", height: "25px", borderRadius: "50%", border: "1px solid #333333a0"}} className='oms-min-size'><span></span></div>
                                                    </div>
                                                }

                                                {data && data.size && 
                                                    <div>
                                                        <div className="product-color-oms">Size</div>
                                                        <div style={{border: "1px solid #333333a0", width: "auto", height: "auto", borderRadius: "2px", textAlign: "center", padding: "3px"}}><span className="size-oms">{data.size}</span></div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-8 col-lg-8">
                                             <table className="table">
                                                <thead className="thead-light">
                                                    <tr align="middle">
                                                        <th scope="col" className="waybill-header-details">Waybill No.</th>
                                                        <th scope="col" className="waybill-header-details">Product Name</th>
                                                        <th scope="col" className="waybill-header-details">Product Category</th>
                                                        <th scope="col" className="waybill-header-details">Store Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody align="middle">
                                                    <tr>
                                                        <td className="waybill-no-details">{trackingId ? trackingId : "-" }</td>
                                                        <td className="waybill-no-details">{data && data.ItemName}</td>
                                                        <td className="waybill-no-details">{data && data.category}</td>
                                                        <td className="waybill-no-details">{ data && data.store_name}</td>
                                                    </tr>
                                                  
                                                </tbody>
                                            </table>
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr align="middle">
                                                        <th scope="col" className="waybill-header-details">Order Qty</th>
                                                        <th scope="col" className="waybill-header-details">Payment Type</th>
                                                        <th scope="col" className="waybill-header-details">Order Status</th>
                                                        <th scope="col" className="waybill-header-details">Client Name</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody align="middle">
                                                    <tr>
                                                        <td className="waybill-no-details">{data ? data.Quantity : "-"}</td>
                                                        <td className="waybill-no-details payment-type">{(orderedItems  && orderedItems.IsCashOnDelivery) || (orderedItems && orderedItems.PaymentType === "Wallet") ? "Cash on Delivery" : "Paystack"}</td>
                                                        <td className="waybill-no-details"><span className={textStyle}>{data && data.status}</span></td>
                                                        
                                                        <td className="waybill-no-details">{receiverName && receiverName}</td>
                                                    </tr>
                                                  
                                                </tbody>
                                            </table>

                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr  align="left">
                                                        <th scope="col" className="waybill-header-details">Phone No.</th>
                                                        <th scope="col" className="waybill-header-details">Email Address</th>
                                                        <th scope="col" className="waybill-header-details">Delivery Address</th>
                                                    </tr>
                                                </thead>
                                                <tbody align="left">
                                                    <tr>
                                                        <td className="waybill-no-details">{orderedItems && orderedItems.ReceiverPhoneNumber}</td>
                                                        <td className="waybill-no-details email-address"><a href={`mailto:${orderedItems && orderedItems.ReceiverEmail}`}>{orderedItems && orderedItems.ReceiverEmail}</a></td>
                                                        <td className="waybill-no-details">{orderedItems && orderedItems.ReceiverAddress}</td>
                                                    </tr>
                                                  
                                                </tbody>
                                            </table>
                                               
                                        </div>
                                    </div>
                            </div>        
                        </div>       
                    </Popup>
                }
             
             </> : ""}
         
        </div>
      )
}

export default OrderItems