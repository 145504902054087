
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {GET_STORE_FAILED, GET_STORE_REQUEST, GET_STORE_SUCCESS,STORE_OWNER_LIST_REQUEST, STORE_OWNER_LIST_SUCCESS } from '../../constants/storeConstant';
import { localData } from '../../helpers/auth/auth';

import { getUrl } from '../../helpers/getUrl';
import { STORE_OWNER_LIST_FAIL } from './../../constants/storeConstant';
const token = localData && localData.token


        export function getStoreAction (ownerId) {

        return async(dispatch,getState) => {
            try {
                dispatch({type: GET_STORE_REQUEST});
       
              
                const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}/store/customer_store?owner=${ownerId}`, {
                    method: "GET",
                    headers: {
                         ACCEPT: "application/json",
                         "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                const data = await res.json();
                if(data.error){
                    return dispatch({type: GET_STORE_FAILED, payload: data.message})
                }
                dispatch({type: GET_STORE_SUCCESS, payload: data})
            } catch (error) {
                const message =
                error.response && error.response.data
                ? error.response.data
                : error.message;
                dispatch({type: GET_STORE_FAILED, payload: message})
            }
    } 
}



export const storeOwnerApi = createApi({
    reducerPath: "storeOwnerApi",
    baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
    
        // If we have a token set in state, let's assume that we should be passing it.
        if(token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }}),
      tagTypes: ["StoreOwner"],
    endpoints: (builder) => ({
        listStoreOwners: builder.query({
            query: ({page, limit, isStoreName}) => ({
                url: `/user/store_owners?page=${page}&limit=${limit}&isStoreName=${isStoreName}`,
                method: "get",
            }),
           providesTags: ["StoreOwner"]
        }),
        searchStoreOwners: builder.query({
            query: ({isStoreName=true, searchTerm}) => ({
                url: `/user/store_owners?isStoreName=${isStoreName}&searchTerm=${searchTerm === undefined ? "" : searchTerm}`,
                method: "get",
            }),
           providesTags: ["StoreOwner"]
        }),
        deactivateStore: builder.mutation({
            query: ({userId, status}) => ({
                url: `/user/deactivate`,
                method: "put",
                body: {userId, status}
            }),
           invalidatesTags: ["StoreOwner"]
        }),
    })
        
});

// export function StoreOwnersList({page,limit}){
//       return async (dispatch, getState) => {
//               dispatch({type: STORE_OWNER_LIST_REQUEST})
//              try {
       
//                 const res = await fetch(`${getUrl(process.env.REACT_APP_ADMIN_BASE_URL_PRODUCTION, process.env.REACT_APP_ADMIN_BASE_URL)}/user/store_owners?page=${page}&limit=${limit}`, {
//                     method: "GET",
//                     headers: {
//                          ACCEPT: "application/json",
//                          "Content-Type": "application/json",
//                         "Authorization": `Bearer ${token}`
//                     }
//                 })
//                 const data = await res.json()
//                 if(data.error){
//                     return dispatch({type: STORE_OWNER_LIST_FAIL, payload: data.message})
//                 }
//                 dispatch({type: STORE_OWNER_LIST_SUCCESS, payload: data.results})
//              } catch (error) {
//                 const message =
//                 error.response && error.response.data
//                 ? error.response.data
//                 : error.message;
//                 dispatch({type: STORE_OWNER_LIST_FAIL, payload: message})  
//              }
//       }
// }



export const {useListStoreOwnersQuery, useDeactivateStoreMutation, useLazySearchStoreOwnersQuery} = storeOwnerApi;