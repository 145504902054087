import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({startDate, setStartDate, endDate, setEndDate}) => {
  console.log("dates",startDate, endDate)


  return (
    <div className="earning__date-range">
      <div className="start-date">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="MM-dd-yyyy" 
          placeholderText="start date"
          className="earning-input"
        />
      </div>
      <div className="end-date">
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="MM-dd-yyyy"
          placeholderText="end date"
          className="earning-input"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
