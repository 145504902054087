import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { uploadToS3 } from "./UploadToS3";


export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    uploadFrontViewImage: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
    uploadLeftViewImage: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
    uploadRightViewImage: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
    uploadRearViewImage: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
    uploadVideo: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
  }),
});

export const { useUploadFrontViewImageMutation, useUploadVideoMutation,useUploadLeftViewImageMutation, useUploadRightViewImageMutation, useUploadRearViewImageMutation} = uploadApi;
