import {useState, useContext, useLayoutEffect} from "react";
import { CiCalendar } from "react-icons/ci";
import { AppContext } from "../../AppContext";
import DoughnutChart from "./DoughnutChart";
import LineChart from "./LineChart";
import  DatePicker from 'react-datepicker';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GetSalesOverviewAction, useSalesOverviewQuery } from "../../actions/Admin/sales/SalesOverviewActions";
import ChartBtn from "../Reusables/ChartBtn";

const SalesGraph = () => {
    const [btnColor, setBtnColor] = useState(1);
   
    const dispatch = useDispatch()
    const { weekly, monthly, yearly, handleCalenderWeekly, handleCalenderMonthly, handleCalenderYearly, dateRange, handleDateRange} = useContext(AppContext);
    const [startDate, setStartDate] = useState(new Date());

   const weeklyData = (weekly || btnColor === 1) && moment(startDate).format("MM/DD/yyyy");
   const monthlyData = (monthly || btnColor === 2) && moment(startDate).format("MM/yyyy");
   const yearlyData = (yearly || btnColor === 3) && moment(startDate).format("yyyy");
   console.log({weeklyData, monthlyData, yearlyData});

   const {data, isError, error, isLoading, isFetching} = useSalesOverviewQuery()

   const fetchOverview = () => {
       if(weeklyData){
            dispatch(GetSalesOverviewAction(`date=${weeklyData}`))
       }else if(monthlyData){
            const data = monthlyData && monthlyData.split("/");
            const month = data && data.at(0);
            const year = data &&  data.at(1)
           dispatch(GetSalesOverviewAction(`month=${month}&year=${year}`))
       }else if(yearlyData){
           dispatch(GetSalesOverviewAction(`year=${yearlyData}`))
       }else {
          return
       }
   }

   useLayoutEffect(() => {
       dispatch(GetSalesOverviewAction(`date=${moment(startDate).format("MM/DD/yyyy")}`))
   }, [])
   

    const handleBgChange = (index) => {
        setBtnColor(index)
    }
    return (
        <div className="my-3">
            <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="card">
                        <div className="header___one">

                            <p>Sales Overview</p>
                            <div className="select-sales-date">
                               <div className="date-range" onClick={handleDateRange}>
                                    <div>
                                        <span>---Select Date---</span>
                                        <span><CiCalendar/></span>
                                    </div>
                                
                               </div>
                               <ChartBtn  handleBgChange={handleBgChange} handleCalenderWeekly={handleCalenderWeekly} btnColor={btnColor} handleCalenderMonthly={handleCalenderMonthly} handleCalenderYearly={handleCalenderYearly}/>
                               {/* <div className="btn-data-sort"><button className={btnColor === 1 ? "weekly-btn active-review-btn": "weekly-btn"} onClick={() => {handleBgChange(1); handleCalenderWeekly()}}>Weekly</button><button className={btnColor === 2 ? "active-review-btn monthly-btn" : "monthly-btn"} onClick={() => {handleBgChange(2); handleCalenderMonthly()}}>Monthly</button><button className={btnColor === 3 ? "active-review-btn yearly-btn" : "yearly-btn"} onClick={() => {handleBgChange(3); handleCalenderYearly()}}>Yearly</button></div> */}
                            </div>
                        </div>
                        <hr className="overview__hr"/>
                        <div className="card-body prod_card_donutchart">
                            <LineChart />
                            <div className="switch-year-month-week weekly-monthly-yearly">
                            {dateRange && <div className="card">
                                        <div className="card-body">
                                            <div className="date__range-picker">
                                               <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showIcon/>
                                               <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showIcon/>
                                            </div>
                                       
                                           <div className="calender-btn-style"><button className="apply-btn" onClick={fetchOverview}>APPLY</button><button onClick={handleDateRange} className="close-btn">CLOSE</button></div>
                                        </div>
                                    </div>}
                                {weekly && <div className="card">
                                        <div className="card-body">
                                           <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showIcon/>
                                           <div className="calender-btn-style"><button className="apply-btn" onClick={fetchOverview}>APPLY</button><button onClick={handleCalenderWeekly} className="close-btn">CLOSE</button></div>
                                        </div>
                                    </div>}
                                {monthly && <div className="card">
                                        <div className="card-body">
                                           <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showMonthYearPicker  showIcon  dateFormat="MM/yyyy"/>
                                           <div className="calender-btn-style"><button className="apply-btn" onClick={fetchOverview}>APPLY</button><button onClick={handleCalenderMonthly} className="close-btn">CLOSE</button></div>
                                        </div>
                                    </div>}
                                {yearly && <div className="card">
                                        <div className="card-body">
                                           <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showYearPicker dateFormat="yyyy"  showIcon/>
                                           <div className="calender-btn-style"><button className="apply-btn" onClick={fetchOverview}>APPLY</button><button onClick={handleCalenderYearly} className="close-btn">CLOSE</button></div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="card">
                        <div className="header___two"><p>Order status Overview</p></div>
                        <hr className="overview__hr"/>
                        <div className="card-body  prod_card_donutchart">
                            <DoughnutChart />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SalesGraph