import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import SparkMD5 from "spark-md5";

// Initialize the S3 client
const s3Client = new S3Client({
  region: process.env.REACT_APP_S3_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  },
});

// Encode a hex string to Base64
function hexToBase64(hex) {
  const binaryString = hex
    .match(/.{2}/g) // Split hex string into bytes (2 chars each)
    .map((byte) => String.fromCharCode(parseInt(byte, 16))) // Convert each byte to a character
    .join("");
  return window.btoa(binaryString); // Encode the binary string to Base64
}

// Function to handle file uploads
export const uploadToS3 = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const md5Hash = SparkMD5.ArrayBuffer.hash(arrayBuffer);
      const contentMd5 = hexToBase64(md5Hash);
  
      const key = `${Date.now()}_${file.name}`;
  
      const command = new PutObjectCommand({
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: key,
        Body: file,
        ContentMD5: contentMd5,
        ContentType: file.type,
      });
  
      await s3Client.send(command);
  
      const fileUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/${key}`;
  
      return { data: { url: fileUrl } };
    } catch (error) {
      console.error("Error uploading to S3:", error);
      return { error: error.message || "An unknown error occurred during upload" };
    }
  };
  
