import { useContext,useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SortDropDown from '../Orders/SortDropdown';
import {BsFunnel, BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {useDeactivateStoreMutation, useLazySearchStoreOwnersQuery, useListStoreOwnersQuery } from '../../actions/Admin/StoreAction';
import { handleAlphaError } from '../../utils/handleAlphaError';
import Loaders from '../Reusables/Loader';


import "./customer.css";
import { toast } from 'sonner';
import useDebounce from '../../helpers/useDebounce';

const SellerTable = ({match}) => {
  
    const {itemLimit, setItemLimit, handleNextPage, searchTerm} = useContext(AppContext);
    const [localLoadingId, setLocalLoadingId] = useState(null);
    const [itemPage, setItemPage] = useState(1)
    const {isError, error, data, isFetching, isLoading} = useListStoreOwnersQuery({page: itemPage, limit: itemLimit, isStoreName: true});

    const [triggerSearch, { data: dataSearch }] = useLazySearchStoreOwnersQuery();
    const errorMessage = handleAlphaError(isError,error, "data");

    const result = data && data.results;
    const searchResult = dataSearch && dataSearch.results


    const storeOwnersList = searchTerm !== "" ? searchResult?.docs  : result?.docs;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);




    const totalPages = result && result.totalPages;
    const page = result && result.page;

    const [deactivateStore] = useDeactivateStoreMutation();


    const handleDeactivate = async (userId, accountStatus) => {
       
         // Check if accountStatus is valid before proceeding
        if(accountStatus !== "active" && accountStatus !== "deactivated") {
            toast.error("Invalid account status.");
            return;
        }

        setLocalLoadingId(userId); // Set the current button as loading
        try {
          await deactivateStore({
            userId,
            status: accountStatus === "active" ? "deactivated" : "active",
          }).unwrap();
    
          toast.success(`Store ${accountStatus === "active" ? "deactivated" : "activated"} successfully!`);
        } catch (error) {
          console.error("Error deactivating store:", error);
          toast.error("Error: Unable to update status.");
        } finally {
          setLocalLoadingId(null); // Reset the loading state
        }
      };


       // Effect to handle search logic based on debounced value
       useEffect(() => {
        if(debouncedSearchTerm === "" || debouncedSearchTerm){
            triggerSearch({ isStoreName: true, searchTerm: debouncedSearchTerm });
        }
    }, [debouncedSearchTerm, triggerSearch]);
    
   
    return (
        <>
           {isError && 
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <hr/>
                    <p className='text-center text-danger login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>
                </div>
                
              }
                <div className="card card_wrapper  seller_table_wrapper mt-4">
                    <div className="data_heading">
                    <div className="header_order_title">STORE OWNERS</div>
                    <div className="button_sort"><BsFunnel className="icon_one"/><span>  <SortDropDown /></span></div>
                </div>
                <div className="card-body">
                {
                   isLoading && <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={isLoading} color="#000000" size={20} /></div>
                }
                {
                   storeOwnersList && storeOwnersList.length > 0 ? 
                    <div className="table-responsive" >
                        <table className="table seller_table">

                        <thead className="tborder table-header">
                        <tr className="align_student_table">
                            <th scope="col" className="header__name">Store name</th>
                            <th scope="col" className="header__status">Status</th>
                            <th scope="col" className="header__userId">User ID</th>
                            <th scope="col" className="header_phone">Phone Number</th>
                            <th scope="col" className="header_email">Email Address</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        {
                              storeOwnersList && storeOwnersList.map((owner) => (
                                <tbody key={owner._id}>
                                <tr className={owner.status  === "active" ? "item_suspend_active table_text" : "item_suspend_inactive table_text"}>
                                     
                                    <td className="item_name owner-name"><img src={owner.image_url || "/alpha-favicon.png"} className="store-logo" alt="store-logo"/>&nbsp;&nbsp;<Link className="item__name" to={`${match.url}/storeowner-details/${owner._id}`}>{owner.storeName || "Owner"}</Link></td>
                                    <td className="item_status">{owner.status}</td>
                                    <td className="item_userId">{owner._id}</td>
                                    <td className="item_phone">{owner.phone}</td>
                                    <td  className="item_email">{owner.email}</td>
                                    {/* <td className={owner.status  === "active" ? "item_suspend" : "item_suspended"}><button>Suspend</button></td> */}
                                 
                                    <td className={"item_deactivate"}>
                                        {
                                           (owner.status !== "active" || owner.status !== "deactivated") ? (
                                            <button 
                                            onClick={() => handleDeactivate(owner._id, owner.status)}
                                            disabled={localLoadingId === owner._id}>
                                              {localLoadingId === owner._id
                                                  ? "Processing..."
                                                  : `${owner.status === "active" ? "Deactivate" : "Activate"}`
                                              }
                                              
                                              </button>
                                           ) : null
                                        }
                                 
                                    </td>
                                </tr>
                    </tbody>
                              ))
                        }
                        
            </table>
            
            </div>
            :  <>{!isLoading && <h2 className="text-center no-record-found">No Records Found</h2>}</>
        }
        </div>
           {
                searchTerm === "" && (
                    <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                            
                    <div className="page_number">Item per page {" "}{" "}
                        <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                    </div>
                { totalPages > 1 ? 
                    <div className="paginate_reuse">
                        <button disabled={isFetching || isLoading} className="left-chevron" onClick={() => handleNextPage("prev", totalPages, setItemPage, page)}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button disabled={isFetching || isLoading} className="right-chevron" onClick={() => handleNextPage("next", totalPages, setItemPage, page)}><BsChevronRight /></button>
                    </div>: null
                }
              </div>   
                )
           }
            
        </div>

            
       

        </>
    )
}

export default SellerTable

SellerTable.propTypes = {
    header_title: PropTypes.string
  };


