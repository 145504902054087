import { useEffect, useContext, useState} from 'react';
import { BsChevronLeft, BsChevronRight} from "react-icons/bs";
import { useSelector, useDispatch } from 'react-redux';
import { EarningChart, TotalEarning, useEarningChartQuery, useEarningListQuery, useTotalEarningQuery } from '../../actions/Admin/TransactionAction';
import { AppContext } from '../../AppContext';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {DatePicker} from 'antd';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./transaction.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import DateRangePicker from './Date';
import { handleAlphaError } from '../../utils/handleAlphaError';
import Loaders from '../Reusables/Loader';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );


const Earnings = ({match}) => {
    const [itemPage, setItemPage] = useState(1)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [date, setDate] = useState(new Date());
    const {itemLimit,setItemLimit,numberSeperator, setStoreId, nFormatter, handleNextPage } = useContext(AppContext);

    const {isError : isEarningListError, error : earningListError, data: earningListData, isFetching, isLoading} = useEarningListQuery({page: itemPage, limit: itemLimit});
    const {isError: isTotalEarningError, isLoading: isLoadingTotalEarning, error: totalEarningError, data: totalEarningData} = useTotalEarningQuery({startDate : moment(startDate).format("MM-DD-YYYY"), endDate : moment(endDate).format("MM-DD-YYYY")});
    const {data: chartEarningData} = useEarningChartQuery({date: moment(date).format("MM-DD-YYYY")})
    const errorMessage = handleAlphaError(isEarningListError,earningListError, "data");
    const totalEarningErrorMessage = handleAlphaError(isTotalEarningError,totalEarningError, "data")

    
    const result = earningListData && earningListData.results;

    const earningList = result && result.docs;


    const totalPages = result && result.totalPages;
    const page = result && result.page;

    const chartData = chartEarningData && chartEarningData.results && chartEarningData.results.chart_arr;

    const totalEarningResult = totalEarningData?.results

    console.log("totalEarningData", totalEarningData, date, chartData)

    const handleNext = (id) => {
          if(id){
            setStoreId(id)
          }
    }
 

//   useEffect(() => {
//     const date1 = moment(date).format("MM-DD-YYYY")
//          const date = {startDate : date1}
//          dispatch(EarningChart(date))
//   }, [startDate, dispatch])
    
//  useEffect(() => {
//      const startDate = dateRange.at(0);
//      const endDate = dateRange.at(1);
//      if(dateRange.length === 2){
//         dispatch(TotalEarning({startDate,endDate}))
//      }
//  }, [dateRange,dispatch])
//   const handleNextPage = (page_number) => {
//     const page = page_number;
//     const limit = +itemLimit;
//     const data = { page, limit };
//     dispatch(EarningList(data));
    
// };
// const totalPages = earning_lists && earning_lists.totalPages;
// const page = earning_lists && earning_lists.page;
// const prevPage = earning_lists && earning_lists.prevPage;
// const nextPage = earning_lists && earning_lists.nextPage;
    

    const data = {
        labels: ["Jan", "Feb", "Mar", "April", "May", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                data: chartData,
                backgroundColor: "#1A1A1A",
                borderColor: "#ED3237",
                borderWidth: 2,
                fill: false,
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
               radius: 0,
            }
        ]
    }
 
    const options = {
        responsive: true,
      plugins: {
        legend:{
            display: false
        }
        
       }
    }

    return (
        <>
             <div className="card card_wrapper  seller_table_wrapper my-2 p-4 merchant__earning_and-chart">
                  <div className="total__merchants-earning">
                       <h3>TOTAL MERCHANT EARNINGS</h3>
                       {
                          isLoadingTotalEarning && !isTotalEarningError ? (
                            <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={isLoadingTotalEarning} color="#000000" size={30} /></div>
                          ) : (
                            <h1>&#x20A6; {totalEarningResult && totalEarningResult.length > 7 ? nFormatter(totalEarningResult) : numberSeperator(totalEarningResult)}</h1>
                          )
                       }
                    <DateRangePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                    {
                        isTotalEarningError && <p className='text-center text-danger login__error-msg'>{totalEarningError?.hasOwnProperty("data") ? JSON.parse(totalEarningErrorMessage)["message"] : JSON.parse(totalEarningErrorMessage)}</p>
                    }
                  
                  </div>
                  <div className="merchant__earning-chart">
                      <div className='choose-date'>
                          <div className='total-header'>Total Merchants Earnings</div>
                          <div className="date-picking"><DatePicker onChange={(value) => setDate(moment(value).format("MM-DD-YYYY"))} defaultValue={moment()} format={"MM-DD-YYYY"} /></div>
                      </div>
                      <div>
                         <Line data={data} options={options}></Line>
                      </div>
                  </div>
             </div>
            <div className="card card_wrapper  seller_table_wrapper">
                    <div className="data_heading">
                        <div className="header_order_title ">STORE EARNINGS</div>
                    </div>
                            {isEarningListError && 
                        <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">Error!</h4>
                            <hr/>
                            <p className='text-center text-danger login__error-msg'>{earningListError?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>
                        </div>
                        
                    }

                   {
                     isLoading && <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Loaders loading={isLoading} color="#000000" size={30} /></div>
                   }
       
                     {
                        earningList && earningList.length > 0 ? 
                            <div className="table-responsive">
                            <table className="table seller_table">

                                
                            <thead className="tborder table-header px-4"  align="left">
                                <tr className="align_student_table">
                                    <th scope="col" className="header___status">Store Name</th>
                                    <th scope="col" className="header__userId">Total Earning</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                
                                <tbody align="left">
                                    {
                                        earningList && earningList.map(({_id, all_time_earning
                                        , store_id}) => (
                                            <tr className="transactions-item" key={_id}>
                                            <td className="item">{store_id && store_id.name}</td>
                                            <td className="item">&#x20A6; {all_time_earning.length > 7 ? nFormatter(all_time_earning) : numberSeperator(all_time_earning) }</td>
                                            <td  className="item earning-link" onClick={() => {handleNext(store_id && store_id._id)}}><Link to={`${match.url}/history`}>View Transactions</Link></td>
                                        </tr>
                                        ))
                                    }
                                
                                </tbody>
                    </table>
                          </div>: <>{!isLoading && <h2 className="text-center no-record-found">No Records Found</h2>}</>
                      }
                    <div style={{display: "flex", alignItems: "center", marginTop: "20px", padding: "0 0.6rem"}}>
                            
                            <div className="page_number">Item per page {" "}{" "}
                                <input type="number" value={itemLimit} onChange={(e) => setItemLimit(e.target.value)} min={0}/>
                            </div>
                            { totalPages > 1 ? 
                                <div className="paginate_reuse">
                                    <button disabled={isFetching} className="left-chevron" onClick={() => handleNextPage("prev", totalPages, setItemPage, page)}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button disabled={isFetching} className="right-chevron" onClick={() => handleNextPage("next", totalPages, setItemPage, page)}><BsChevronRight /></button>
                                </div>: null
                            }
                    </div>  
            </div>
        </>
    )
}

export default Earnings


